import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Modules from '../components/modules'

const HomepageTemplate = ({ pageContext }) => {
  return (
    <Layout classNames="home page-template-default page" inverseHeader={false}>
      <Seo title="Home" seoTitle={pageContext.seo.seoTitle} seoDescription={pageContext.seo.seoDescription} />
      <section className="content-wrap">
        <Modules modules={pageContext.modules} />
      </section>
    </Layout>
  )
}

export default HomepageTemplate